<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'balance',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmBalance/list',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '店铺名称',
          key: 'shopName',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '券名',
          align: 'left'
        },
        {
          dataIndex: 'amount',
          title: '金额',
          align: 'left'
        },
        {
          dataIndex: 'sendNum',
          title: '发放人数',
          align: 'left'
        },
        {
          dataIndex: 'usedAmount',
          title: '已消费',
          align: 'left',
          customRender: (text, record) => {
            return `￥${text}/￥${record.allAmount}(${record.usedRate}%)`
          }
        },
        {
          dataIndex: 'shopNames',
          title: '针对店铺',
          align: 'left',
          type: 'tagGroup',
          getData: records => (records.shopNames ? records.shopNames.split(',').map(e => e) : [])
        },
        {
          dataIndex: 'balanceType',
          title: '类型',
          align: 'left',
          filters: [
            {
              text: '余额券',
              value: '0'
            },
            {
              text: '余额卡',
              value: '1'
            }
          ],
          filterMultiple: false,
          customRender: (text, record) => {
            return text == 1 ? '余额卡' : '余额券'
          },
          onExport: (text, record) => {
            return text == 1 ? '余额卡' : '余额券'
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '用户管理',
                onClick: () => {
                  let link = {
                    '0': 'indexVoucher',
                    '1': 'indexCard'
                  }[records.balanceType]
                  this.$router.push({
                    path: `/marketingCenter/${link}`,
                    query: {
                      id: records.id
                    }
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push({
                    path: '/marketingCenter/balanceDetail',
                    query: {
                      id: records.id
                    }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmBalance/del',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push({
              path: '/marketingCenter/balanceDetail'
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
